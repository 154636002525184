function createImage(businessFile = {}) {
  const {
    _id,
    description,
    altText,
    gatsbyFile,
    file: fileUrl,
    ...restFile
  } = businessFile;

  if (gatsbyFile) {
    return {
      ...restFile,
      ...gatsbyFile.childImageSharp,
      _id,
      altText: altText || description,
    };
  }
  return fileUrl;
}

export function createImages({ images = [] }) {
  return images.map(createImage);
}

export function getComponentFiles(options) {
  const {
    components = [],
    componentName = "",
    componentIdentifier = "",
    numToSelect,
    selectionOffset = 0,
  } = options;

  const selectedComponents = components.filter(
    ({ fastId, componentIdentifier: thisComponentIdentifier = "" }) => {
      if (!componentName) {
        return componentIdentifier === thisComponentIdentifier;
      }
      if (!componentIdentifier) {
        return fastId === componentName;
      }
      return (
        fastId === componentName &&
        componentIdentifier === thisComponentIdentifier
      );
    }
  );

  if (!selectedComponents.length) {
    return null;
  }

  const selectedComponent = selectedComponents[0];

  if (!selectedComponent.files.length) {
    return null;
  }

  const files = selectedComponent.files.map((businessFile) => {
    return createImage(businessFile);
  });

  if (!numToSelect) {
    return files;
  }

  if (numToSelect === 1) {
    if (selectionOffset > files.length - 1) {
      return null;
    }
    return files[selectionOffset];
  }

  return files.slice(selectionOffset, selectionOffset + numToSelect + 1);
}

export function getBusinessFiles({
  businessFileIds = [],
  businessFiles = [],
  numToSelect,
  filesField = "files",
  0: iteratorObject, // this is a workaround to get the iterator object to work MultipleComponentIterator
}) {
  let ids = businessFileIds || [];

  if (ids.length === 0 && iteratorObject) {
    ids = iteratorObject[filesField] || [];
  }
  if (typeof ids === "number" || typeof ids === "string") {
    ids = [ids];
  }

  const files = (ids || [])
    .map((businessFileId) => {
      const businessFile = businessFiles.find(
        ({ _id }) => _id === businessFileId
      );

      if (businessFile) {
        return createImage(businessFile);
      }
      return false;
    })
    .filter(Boolean);

  if (!files || !files.length) {
    return null;
  }

  if (!numToSelect) {
    return files;
  }

  if (numToSelect === 1) {
    return files[0];
  }

  return files.slice(0, numToSelect);
}

export function getPageHeroImage({
  options,
  numToSelect,
  selectionOffset = 0,
}) {
  const heroElementComponents = options.filter(
    ({ fastId }) => fastId === "HeroElement"
  );

  const imageOptions =
    heroElementComponents.length > 0 &&
    heroElementComponents[0].files.length > 0
      ? heroElementComponents[0].files.map(({ file, gatsbyFile, altText }) => {
          if (gatsbyFile) {
            return { ...gatsbyFile.childImageSharp, altText };
          }
          return file;
        })
      : [];

  if (!numToSelect) {
    return imageOptions;
  }

  if (numToSelect === 1) {
    return imageOptions[selectionOffset];
  }

  return imageOptions.slice(selectionOffset, selectionOffset + numToSelect + 1);
}

export function getPageGalleryImages({
  options,
  numToSelect,
  selectionOffset = 0,
}) {
  const imageCollectionComponents = options.filter(
    ({ fastId }) => fastId === "ImageCollection"
  );

  const imageOptions =
    imageCollectionComponents.length > 0
      ? imageCollectionComponents[0].files.map(
          ({ file, gatsbyFile, altText }) => {
            if (gatsbyFile) {
              return { ...gatsbyFile.childImageSharp, altText };
            }
            return file;
          }
        )
      : [];

  if (!numToSelect) {
    return imageOptions;
  }

  if (numToSelect === 1) {
    return imageOptions[selectionOffset];
  }

  return imageOptions.slice(selectionOffset, selectionOffset + numToSelect + 1);
}

export function filterRegularLinks({ links }) {
  return links.filter(({ props }) => {
    return JSON.parse(props).as !== "Button";
  });
}

export function filterButtonLinks({ links }) {
  return links.filter(({ props }) => {
    return JSON.parse(props).as === "Button";
  });
}

export function getComponentContentNodeContent({
  components,
  componentName,
  componentId,
  defaultValue = "",
  selectFirst = true,
  enforceDefault = false,
}) {
  if (!componentId || !components || !components.length) {
    return defaultValue;
  }

  const foundComponents = components.filter(
    ({ fastId, componentIdentifier }) => {
      if (!componentName) {
        return componentIdentifier === componentId;
      }
      return fastId === componentName && componentIdentifier === componentId;
    }
  );

  if (foundComponents.length === 0) {
    return defaultValue;
  }
  if (!selectFirst && foundComponents.length > 1) {
    return defaultValue;
  }

  const foundComponent = foundComponents[0];
  const { contentNodes = [] } = foundComponent;

  if (contentNodes.length === 0) {
    return defaultValue;
  }
  if (!selectFirst && contentNodes.length > 1) {
    return defaultValue;
  }

  const contentNode = contentNodes[0];
  const { content = "" } = contentNode;

  if (!content && enforceDefault) {
    return defaultValue;
  }

  return content;
}

export function withArgs({ func, args = [] }) {
  return (...funcArgs) => func(...funcArgs, ...args);
}

export function withNamedArgs({ func, args = {} }) {
  return (...funcArgs) => {
    const finalArgs = {
      ...args,
      ...funcArgs,
    };
    return func(finalArgs);
  };
}

export function getParentGeneratedChildPages({
  pages,
  parentPageId,
  parentPageType,
  generatedPages,
  tagName,
  includeParentPage = true,
}) {
  if (!parentPageId && !parentPageType) {
    return [];
  }

  const parentPage = pages.find(
    ({ _id, pageType }) => _id === parentPageId || pageType === parentPageType
  );

  const childPages = generatedPages
    .filter(({ context = {} }) => {
      const {
        fishermanParentPageId: currentParentPageId,
        fishermanParentPageType: currentParentPageType,
        tagName: currentTagName,
      } = context;

      if (tagName && currentTagName !== tagName) {
        return false;
      }

      if (parentPageType && parentPageType !== currentParentPageType) {
        return false;
      }

      if (parentPageId && parentPageId !== currentParentPageId) {
        return false;
      }

      return true;
    })
    .map(({ path, context }) => {
      const { title } = context;
      return { slug: path, title };
    });

  if (parentPage && includeParentPage) {
    return [parentPage, ...childPages];
  }
  return childPages;
}

export function joinParentWithChildPages({ pages = [], generatedPages = [] }) {
  return (pages || []).map((parentPage) => {
    const { _id: parentPageId, type: parentPageType } = parentPage;

    const childPages = (generatedPages || [])
      .filter(({ context = {} }) => {
        const {
          fishermanParentPageId: currentParentPageId,
          fishermanParentPageType: currentParentPageType,
        } = context;
        return (
          currentParentPageId === parentPageId ||
          currentParentPageType === parentPageType
        );
      })
      .map(({ path, context }) => {
        const {
          title = "",
          navigationOrder = -1,
          navigationGroup = "",
          navigationGroupOrder = -1,
        } = context || {};
        return {
          slug: path,
          title,
          navigationOrder,
          navigationGroup,
          navigationGroupOrder,
        };
      });
    return { ...parentPage, childPages };
  });
}
